

//sidebar right
[direction="rtl"] {
    .sidebar-right {
        left: -50rem;
        right: auto;
		box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1);
			
        &.show {
            left: 5.25rem;
            right: auto;
        }

        .sidebar-right-trigger {
            left: 100%;
            right: auto;
			border-radius: 0 5px 5px 0;
			box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
        }
    }
	.sidebar-right .sidebar-close-trigger{
		left: -48px;
		right: auto;
	}
	.bootstrap-select .dropdown-toggle .filter-option{
		text-align:right;
	}
	
	.dz-demo-panel{
		right:unset;
		left: -100%;
		&.show{
			right:unset;
			left:0;
		}
		.dz-demo-trigger{
			left:100%;
			right:unset;
			border-radius: 0 5px 5px 0;
		}
	}
	
}

