/* .form-head{
	padding: 45px 0 130px 0;
    margin-bottom: -130px;
	background-size:cover;
	&>div{
		
	}
} */
.heart{
	width: 60px;
    height: 60px;
    display: inline-block;
    background: url('../images/like.png');
    cursor: pointer;
    margin: -25px -15px;
}
.heart-blast{
	background-position: -1680px 0 !important;
    transition: background 1s steps(28);
}

.plus-icon{
	height:60px;
	width:60px;
	line-height:60px;
	font-size:24px;
	@include transitionMedium;
	min-width:60px;
	text-align:center;
	border-radius:$radius;
	background:rgba($white,0.2);
	color:$white;
	&:hover{
		color:$white;
	}
}

.deznav .copyright {
    font-size: 14px;
    padding: 0 45px;
    margin-top: 40px;
    color: #969BA0;
    margin-bottom: 40px;
}

.currency-icon{
		margin-top:-34px;

}
.caret{
	a{
		height:40px;
		width:50px;
		background:$secondary;
		display:block;
		line-height: 40px;
		text-align: center;
		font-size: 26px;
		margin-right:auto;
		margin-left:auto;
		color: white;
		border-radius:$radius;
		margin-bottom:-18px;
		&:hover{
			color:$white;
		}
		@include respond ('phone'){
			margin-bottom:-10px!important;
			height:35px;
			width:38px;
			font-size:20px;
			border-radius:10px;
			line-height: 40px;
		}
	}
}
.card-bx{
	img{
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 0;
		border-radius: 1.25rem;
		object-fit: cover;
	}
	.card-info{
		position: relative;
		padding: 40px 30px;
		.num-text{
			font-size:28px;
		}
	}
}
.stacked{
	position: relative;
    z-index: 1;
}
.plus-icon{
	height:60px;
	width:60px;
	line-height:60px;
	font-size:24px;
	@include transitionMedium;
	min-width:60px;
	text-align:center;
	border-radius:$radius;
	background:rgba($white,0.2);
	color:$white;
	&:hover{
		color:$white;
	}
}

.deznav .copyright {
    font-size: 14px;
    padding: 0 30px;
    margin-top: 40px;
    color: #969BA0;
    margin-bottom: 40px;
}



.card-tabs{
	.nav-tabs{
		&.style-1{
			padding:0;
			.nav-item{
				.nav-link{
					padding:0;
					padding-right:25px;
					color:#B5B5B5;
					font-size: 18px;
					font-weight: 500;
					&.active{
						background:transparent;
						color:$black;
					}
				}
			}
			@include respond ('laptop'){
				.nav-item{
					.nav-link{
						font-size:14px;
						padding-right: 16px;
					}
				}
			}
		}
		&.style-2{
		border-bottom:0;
			.nav-item{
				.nav-link{
					border-radius: 5rem;
					padding: 15px 20px;
					font-weight: 500;
					font-size: 17px;
					border: 0;
					.badge{
						margin-left: 5px;
						color:$white;
					}
					&.active{
						color:$black;
					}
					@include respond ('laptop'){
						font-size:14px;
					}
					@include respond ('tab-land'){
						padding: 10px 15px;
					}
				}
			}
			@include respond ('phone'){
					margin-bottom: 10px;
				.nav-item{
					.nav-link{
						  padding: 5px 10px;
					}
				}
			}
		}
	}
}

//this-week
.currency-icon{
	margin-top:-65px;
	box-shadow: 0 5px 5px rgba(0, 0, 0,0.25);
	border-radius: 100%;
	@include transitionMedium;
	@include custommq ($max:1600px){
		height:65px;
		width:65px;
	}
	@include respond ('phone'){
		margin-top: -50px;
	}
}
.card-coin{
	&:hover{
		transform: translateY(-10px);
		.currency-icon{
			box-shadow:none;
		}
	}
	h2{
		font-size:30px;
		@include custommq ($max:1600px){
			font-size:22px;		
		}
	}
}
.card-bx{
	img{
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 0;
		border-radius: $radius;
		object-fit: cover;
	}
	.card-info{
		position: relative;
		padding: 25px 30px;
	}
}
.stacked{
	position: relative;
    z-index: 1;
}
//form-wrapper
.form-wrapper{
	.form-group{
		margin-bottom:12px;
	}
	.input-group{
		border-radius:$radius;
		.input-group-prepend{
				display: flex;
				margin-right: -1px;
			.input-group-text{
				background:#5B5288;
				border:0;
				justify-content:left;
				font-size:16px;
				font-weight:500;
				padding-left: 30px;
				min-width:170px;
				color:white;
				border-top-left-radius: $radius;
				border-bottom-left-radius: $radius;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				
				@include respond ('phone'){
					min-width:115px;
				}
			}
		}
		.form-control{
			font-size:20px;
			height:55px;
			border:1px solid $border-color;
			text-align:right;
			border-top-right-radius: $radius;
			border-bottom-right-radius: $radius;
			border-left: 0;
		}
	}
	@include respond ('laptop'){
		.input-group{
			.input-group-prepend{
				.input-group-text{
					font-size:14px;
					padding-left: 20px;
					
					@include respond ('phone'){
						font-size:12px;
					}
				}
			}
			.form-control{
				font-size:16px;
				height:50px;
			}
		}
	}
}
.card-tabs.style-1{
	.nav-tabs{
		border:0;
		border-radius:$radius;
		background-color:#f7f7f7;
		.nav-item{
			margin-bottom:0;
		}
		.nav-link{
			border:0;
			border-radius:$radius;
			&.active{
				background-color:var(--primary);
				color:$white;
			}
		}
	}
} 
.tbl-btn{
	tr{
		td{
			a{
				min-width:110px;
				padding:10px 0;
			}
		}
	}
}
.amount{
	display: block;
    text-align: center;
	align-items: center;
	.form-control{
		text-align:right;
		background:transparent;
		border-left:0;
		font-size:24px;
		height: 75px;
		
	}
	label{
		width:210px;
		background:#5B5288;
		margin:0;
		padding-left: 30px;
		border: 0;
		border-radius:14px;
		
	}
}

//my wallets

.theme-colors{
	.btn{
		height:35px;
		width:35px;
		cursor:pointer;
		border-radius:35px!important;
		padding:0;
		text-align:center;
		margin-right: 12px;
		padding: 3px;
		i{
			font-size:28px;
			color:$white;
			display:none;
		}
		&.active{
			i{
				display:block;
			}
		}
	}
}
.donut-chart-sale small {
    font-size: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: center;
    font-weight: 600;
	z-index: 1;
    color: black!important;
	
}
.default-select{
	&.style-1{
		width: auto !important;
		border-radius:$radius;
		left: 0;
		top: 0;
		height: auto!important;
		border: none;
		.btn{
			color:$white!important;
			border:0!important;
			&:after{
				margin-left: 0.5em;
				font-size: 16px;
			}
		}
		.dropdown-menu{
			z-index: 1;
			.dropdown-item{
				font-size: 16px;
			}
		}
		@include respond ('laptop'){
			.btn{
				padding: 0.625rem 1rem;
				font-size: 0.813rem!important;
			}
			.dropdown-menu{
				.dropdown-item{
					font-size: 0.813rem!important;
				}
			}
		}
	}
	&.style-2{
		width: auto !important;
		left: 0;
		top: 0;
		height: auto!important;
		.btn{
			padding: 0.938rem 1.5rem;
			border-radius: 0.75rem;
			font-weight: 500;
			font-size: 1rem;
			color:var(--primary)!important;
			border:1px solid $border-color!important;
			&:after{
				margin-left: 0.5em;
				font-size: 16px;
			}			
		}
		.dropdown-menu{
			z-index: 1;
			.dropdown-item{
				font-size: 1rem;
			}
		}
	}
}
.underline{
	text-decoration: underline;
}


//transaction
.ic-icon {
    height: 63px;
    width: 63px;
    min-width: 63px;
    display: inline-block;
    line-height: 63px;
    text-align: center;
	border-radius:14px;
	margin-right: 12px;
	@include respond ('phone'){
		height:45px;
		width:45px;
		min-width:45px;
		line-height:45px;
	}
}
//coin-tabs
.coin-tabs{
	.nav-tabs{
		border:0;
		.nav-link{
			border:0;
			border-bottom:2px solid var(--rgba-primary-2);
			&.active{
				color:$black;
				border-color:var(--primary);
			}
		}
	}
}
.detault-daterange{
	width: auto;
	border:1px solid #cfcfcf;
    border-radius: $radius;
	overflow: hidden;
	.form-control{
		border:0;
		padding:0;
		height: 40px;
		font-weight: 600;
	}
	.input-group-text{
		padding:0;
		background:transparent;
		padding-right: 0;
		color:var(--primary);
		border-radius: 0;
		i{
			font-size:28px;
		}
	}
	@include respond ('laptop'){
		.form-control,.input-group-text{
			height:40px;
		}
	}	
}

///coin-holding
	.coin-holding{
		padding:15px 20px;
		align-items:center;
		border-radius:15px;
		position:relative;
		margin-right: 0;
		margin-left: 0;
		z-index:1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&:after{
			position:absolute;
			content:"";
			height:100%;
			width:100%;
			top:0;
			left:0;
			z-index:-1;
			border-radius:15px;
			background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.46) 100%); /* FF3.6-15 */ background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.46) 100%); /* Chrome10-25,Safari5.1-6 */ background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.46) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#75ffffff',GradientType=1 ); /* IE6-9 */
		}
		.coin-font{
			font-size:28px;
			 @include custommq($max: 1600px) {
				font-size:16px;
			}
			@include respond ('tab-land'){
				font-size:16px;
			}
			@include respond ('phone'){
				font-size:20px;
			}
			
		}
		.coin-font-1{
			font-size:30px;
			font-weight: 600;
			 @include custommq($max: 1600px) {
				font-size:16px;
			}
			@include respond ('tab-land'){
				font-size:16px;
			}
		}
		 @include custommq($max: 1600px) {
			.coin-bx{
				svg{
					width:50px;
				}
			}	
		}
		@include respond ('phone'){
			.coin-bx{
				width:100%;
				svg{
					width:50px;
				}
			}
		}
		@include respond ('phone'){
			.coin-bx-one{
				svg{
					width:20px;
				}
			}
		}
		@include respond ('phone'){
			p{
				font-size:11px;
			}
		}
	}
//portofolio
.my-profile{
	position:relative;
	display:inline-block;
	img{
		height:195px;
		width:195px;
		border-radius:15px;
		@include respond ('tab-land'){
			height:100px;
			width:100px;
		}
	}
	a{
		position:absolute;
		height:52px;
		width:52px;
		background:var(--primary);
		text-align: center;
		line-height: 52px;
		border-radius: 15px;
		color: white;
		font-size: 20px;
		top: -20px;
		right: -20px;
		@include respond ('tab-land'){
			height:35px;
			width:35px;
			line-height:35px;
			font-size:14px;
			border-radius: 10px;
		}
	}
}
.name-text{
	font-size:20px;
	@include respond ('tab-land'){
		font-size:16px;
	}
}
.portofolio-social{
	display:flex;
	justify-content:center;
	margin-top:30px;
	li{
		a{
			height:52px;
			width:52px;
			border:1px solid;
			border-color:var(--primary);
			line-height:52px;
			font-size:24px;
			display:block;
			text-align:center;
			color:var(--primary);
			margin:0 8px;
			border-radius:15px;
			@include respond ('tab-land'){
				height:40px;
				width:40px;
				line-height:40px;
				font-size:20px;
			}
			
		}
	}
}
.rank-ic{
	height: 55px;
    width: 55px;
    line-height: 55px;
    display: block;
    border-radius: $radius;
    text-align: center;
	@include respond ('phone'){
		height: 50px;
		width: 50px;
		line-height:50px;
		font-size: 16px!important;
	}
}

.weather-btn{
	background:$white;
	display:flex;
	padding:10px 10px;
	align-items:center;
	border-radius:$radius;
	margin-right:20px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12);
	
	.bootstrap-select .btn{
		background:transparent!important;
		border:0!important;
		padding:0;
		color:$black!important;
	}
}
.tbl-orders{
	background:#F4F4F4;
	border-radius:$radius;
}
.m-t35{
	margin-top:35px;
}
.svg-btn{
	@include respond ('phone'){
		width: max-content;
		padding: 5px 10px;
	}
	svg{
		@include respond ('phone'){
			height: 30px;
			width: 30px;
		}
	}
}
.m-t25{
	@include respond ('phone'){
		margin-top:25px;
	}
}
.custom-dropdown{
	.bootstrap-select button{
		padding:0px;
		border:0!important;
	}
}
.tbl-orders-style{
	svg{
		path{
			stroke:$black;
		}
	}
	tr{
		td{
			color:black;
		}
	}
}	

.chart-content{
	padding:14px 0;
}
.wspace-no{
	white-space: nowrap;
}
.about-coin{
	.title{
		font-size:24px;
		@include respond ('phone'){
			font-size:16px;
		}
	}
	.sub-title{
		font-size:14px;
		@include respond ('phone'){
			font-size:12px;
		}	
	}
	
	@include respond ('phone'){
		img{
			width:60px;
		}
		.sub-title{
			margin-bottom:0;
		}
	}
	span{
		font-size:14px;
		@include respond ('phone'){
			font-size:12px;
		}
	}
}
.card-table{
	overflow:hidden;
	tbody{
		tr{
			td{
				span{
					svg{
						@include respond ('phone'){
							width:40px;
						}
					}
				}
			}
		}
	}
}
.form-head{
	h2{
		font-size:34px;
		@include respond ('phone'){
			font-size:24px;
		}
	}
}
.current-chart{
	margin-bottom:-40px;
}
.swiper-box{
	position: relative;
	height: 920px;
	.swiper-container {
		width: 100%;
		height: 100%;
	}
	.card{
		height:auto;
	}
	.swiper-slide {
	  font-size: 18px;
	  height: auto;
	  -webkit-box-sizing: border-box;
	  box-sizing: border-box;
	  padding-left: 23px;
	}
	.swiper-vertical>.swiper-scrollbar{
		right:auto;
		width:2px;
	}
	.swiper-scrollbar-drag{
		width:8px;
		right:auto;
		background:$secondary;
	}
	&:after{
		content:"";
		height:200px;
		width:100%;
		z-index:1;
		position:absolute;
		left:0;
		bottom:0;	
		background: -moz-linear-gradient(top,  rgba(30,87,153,0) 0%, rgba(166,188,213,0) 33%, rgba(249,249,249,0.43) 53%, rgba(249,249,249,1) 79%); 
		background: -webkit-linear-gradient(top,  rgba(30,87,153,0) 0%,rgba(166,188,213,0) 33%,rgba(249,249,249,0.43) 53%,rgba(249,249,249,1) 79%);
		background: linear-gradient(to bottom,  rgba(30,87,153,0) 0%,rgba(166,188,213,0) 33%,rgba(249,249,249,0.43) 53%,rgba(249,249,249,1) 79%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#f9f9f9',GradientType=0 );
	}
	@include custommq ($max:1600px){
		height:1100px;
	}
	@include respond ("tab-land"){
		height:auto;
		margin-bottom:25px;
		&:after{
			content:none;
		}
		.swiper-slide {
			padding-left:0;
			padding: 0 10px;
		}
	}
	@include respond ('phone'){
		.card{
			margin-bottom:25px;
		}
	}
}

.action-button{
	.btn-info{
		svg path{
			stroke:$info;
		}
	}
	.btn-danger{
		svg path{
			stroke:$danger;
		}
	}
	.btn{
		&:hover,
		&:focus{
			svg path{
				stroke:$white;
			}
		}
	}
}



.apexcharts-tooltip.apexcharts-theme-light{
	border-radius: $radius;
	background: #ffffff;
	color: #828690;
	border: 0 !important;
	box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
}
.apexcharts-xaxistooltip.apexcharts-theme-light{
	border-radius: 0.42rem !important;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
    border: 0 !important;
    background: #ffffff !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title{
	background: #F3F6F9 !important;
    border: 0 !important;
}
.apexcharts-tooltip .apexcharts-tooltip-title {
    padding: 0.5rem 1rem;
}
.apexcharts-xaxistooltip.apexcharts-theme-light:before {
    border-bottom: 0 !important;
}
.apexcharts-xaxistooltip.apexcharts-theme-light:after {
    border-bottom-color: #ffffff !important;
}

/* add css */
.select-btn{
	.default-select{
		&.style-1{
			padding: 0.6rem 0.9rem;     
			font-size: 0.875;     
			font-weight: 400;    
			border-radius: 0.3rem;
			background-color: #6418c3;
			color: white;
		}
		@include respond ('laptop'){
			&.style-1{
				padding: 0.625rem 1rem;
				font-size: 0.813rem!important;
			}
			.dropdown-menu{
				.dropdown-item{
					font-size: 0.813rem!important;
				}
			}
		}
	}
	
}


