.user-card{
	.dz-media{
		height: 80px;
		width: 80px;
		min-width: 80px;
		border-radius: 10px;
		overflow: hidden;
		border: 4px solid #fff;
		box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
		img{
			height:100%;
			width:100%;
			object-fit:cover;
		}
	}
	.icon-placeholder{
		font-size: 24px;
		text-transform: uppercase;
		line-height: 70px;
		font-weight: 500;
		display: block;
		letter-spacing: 2px;
		text-align: center;
		height: 100%;
	}
	.title a{
		color:$black;
		&:hover{
			color:var(--primary);
		}
	}
	.list-group{
		margin: 0 -30px;
		.list-group-item{
			padding: 10px 30px;
		}
	}
}