////////////////
// Compact Nav
////////////////

@media (min-width:767px) {
    [data-sidebar-style="compact"] {
		.nav-header {
			width: 11.25rem;
		}
        .deznav {
            
            .nav-user{
                display: none;
            }
        
            .metismenu {
				&>li{
					&>a{
						padding: 1.2rem 0.5rem!important;
						@at-root [data-layout="horizontal"]#{&} {
							padding:12px 18px !important;
							margin: 0 4px;
							border:0;
						}
						&:hover{
							.nav-text{
								transform: translateX(0px);
							}
						}
					}
				}
                li {
                    text-align: center;
                    a {
                        padding: 0.5rem;
						svg{
						
							max-width: 21px;
							max-height: 21px;
							display: block;
							margin-left: auto;
							margin-right: auto;	
						}
						
                    }
					ul:after{
						content:none;
					}
					
					& > a{
						background: transparent;
						box-shadow: none;
						color: inherit;
					}
                }
            }
			
			.main-profile,.copyright{
				display: none;
			}
			.copyright{
			    padding: 0 20px;
				margin-top: 20px;
			}
        }

        .nav-text {
            display: inline-block;
            margin-top: 0.3125rem;
        }

        .nav-label {
            &.first{
                display: none;
            }
        }

        .nav-badge {
            display: none;
        }

        .footer {
            padding-left: 11.25rem;
        }

        .content-body {
            margin-left: 11.25rem;
        }
		
		&[data-theme-version="dark"]{
			&[data-layout="horizontal"]{
				.deznav {
					.metismenu {
						 li {
							&>a i{
								color:$white;
							}
						 }
					}	
				}
			}
		
		}
		&[data-layout="vertical"]{
			.deznav {
				.metismenu {
					 li {
						&:hover{
							&>a i{
								background:var(--primary);
								color:$white;
							}
						}	
					 }
				}	
			}
		}
    }
}
[data-layout="horizontal"][data-sidebar-style="compact"] {
	.footer {
		padding-left: 0;
	}
	.content-body {
		margin-left:0;
	}
	.deznav{
		margin-bottom:0;
	}
	.nav-header {
		width: 21.75rem;
		.brand-logo {
			padding-left: 40px;
			padding-right: 40px;
		}
	}
}
[data-layout="vertical"][data-sidebar-style="compact"] {
    .deznav {
        width: 11.25rem;
        
        .metismenu {
		
            ul{
				margin-left:0;
				border:0;
				background: rgba(0,0,0,0.02);
				padding: 10px 0;
				
				@at-root [data-theme-version="dark"]#{&} {
					background:rgba(255, 255, 255, 0.05);
				}
					
				a{
					&:before{
						content:none;
					}
				}
				
				ul a {
					padding: 0.625rem 0.9375rem;
					
				}
            }

            &>li {
                &>a.has-arrow::after {
                    top: 1px;
					display: inline-block;
					right: auto;
					margin-left: 5px;
					position: relative;
					width: 7px;
					height: 7px;
					border-width: 2px 0px 0px 2px;
					
					
					@at-root [direction="rtl"]#{&} {
						left: auto;
						margin-left: 0;
						margin-right: 5px;
					}
                }
            }
        }

    }
}