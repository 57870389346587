[data-sidebar-style="mini"],
[data-layout="horizontal"] {
    .nav-control {
        display: none;
    }
}

[data-sidebar-style="overlay"] {
    @include respond('phone-land') {
        .nav-header .logo-abbr {
            display: block;
        }
    }
}

[data-header-position="fixed"] {
    .nav-header {
        position: fixed;
        left: auto;
        // @include respond('big-desktop') {
        //     left: 6rem;
        // }
    }
}

.nav-header {
    height: 4.5rem;
    width: 17.5rem;
    display: inline-block;
    text-align: left;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--nav-headbg);
    transition: all .2s ease;
    z-index: 10;
	
    .logo-abbr {
        min-width: 45px;
		max-width: 40px;
		@include respond('phone') {
			min-width: 35px;
		}
		
    }
    .logo-compact {
        display: none;
    }
    // @include respond('big-desktop') {
    //     left: 3.125rem;
    // }
    @include custommq($max: 1350px) {
        width: 17rem;
    }
	.svg-logo-rect{
		fill:var(--primary);
	}
    .brand-logo {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.125rem;
        color: $white;
        text-decoration: none;
		padding-left: 20px;
		padding-right: 50px;
        font-weight: 700;
		
		
        // &:hover {
        //     color: var(--primary);
        // }
        [data-sidebar-style="compact"] &,
        [data-sidebar-style="mini"] & {
            padding-left: 0;
            padding-right: 0;
            justify-content: center;
        }
        @include respond('phone-land') {
            padding-left: 0;
            padding-right: 0;
            justify-content: center;
        }
    }
    .brand-title {
		margin-left: 20px;
		max-width: 120px;
		margin-top: 0px;
        // &::first-letter{
        //     background: $white;
        //     color: var(--primary);
        //     padding: 1px 10px;
        //     margin-right: 5px;
        //     border-radius: 3px;
        // }
        @at-root [data-theme-version="dark"] & {
            background-position: 0 120%;
        }
    }
}

@include respond('tab-land') {
	/* .nav-header {
		height:5rem;
    } */
}
@include custommq($max:1023px) {
    .nav-header {
        width: 5rem;
        .brand-title {
            display: none;
        }
    }
}

.nav-control {
    cursor: pointer;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    // color: var(--primary);
    font-size: 1.4rem;
    // line-height: 2rem;
    padding: 2px 0.5rem 0;
    // margin-top: 5px;
    border-radius: 2px;
	@include custommq($max:1023px) {
		right:-45px;
	}
    @include respond('phone-land') {
        right: -2.75rem;
    }
	@include respond('phone') {
        right: -3rem;
    }
    // @include respond('desktop') {
    //     right: -3.125rem;
    // }
    @include custommq($min: 1200px, $max: 1500px) {
        // right: 0;
    }
}

////////////////////
// Humbuger Icon
////////////////////
.hamburger {
    // background-color: $white;
    display: inline-block;
    left: 0px;
    position: relative;
    top: 3px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 26px;
    z-index: 999;
}

.hamburger .line {
    background: var(--primary);
    display: block;
    height: 3px;
    border-radius: 3px;;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: auto;
    // width: 22px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
    width: 20px;
}

.hamburger .line:nth-child(2) {
    width: 26px;
}

.hamburger .line:nth-child(3) {
    width: 22px;
}

.hamburger:hover {
    cursor: pointer;
}

.hamburger:hover .line {
    width: 26px;
}


/*
    .hamburger.is-active {
      left: 60px;
    }
    */

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
    width: 10px;
    height: 2px;
}

.hamburger.is-active .line:nth-child(2) {
    -webkit-transform: translateX(-0px);
    transform: translateX(-0px);
    width: 22px;
    height: 2px;
}

.hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(4px) rotate(45deg);
    transform: translateY(4px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-4px) rotate(-45deg);
    transform: translateY(-4px) rotate(-45deg);
}

@media (min-width:767px) {
    [data-sidebar-style="compact"] {
        .nav-control {
            display: none;
        }
        .nav-header {
            width: 11.25rem;
        }
    }
}

[data-sidebar-style="full"][data-layout="vertical"] {
    .menu-toggle {
        .brand-title {
            display: none;
        }
        .nav-header {
            .logo-abbr {
                display: block;
            }
        }
    }
}

[data-sidebar-style="full"][data-layout="horizontal"] {
    .logo-compact {
        display: none;
    }
}

[data-sidebar-style="mini"] {
    .nav-header {
        .logo-abbr {
            display: block;
        }
    }
}

[data-sidebar-style="compact"][data-layout="vertical"] {
    .nav-header {
        .brand-title {
            display: none;
        }
        .logo-compact {
            max-width: 75px;
        }
    }
}
[data-sidebar-style="compact"][data-layout="horizontal"] {
    .nav-header .brand-logo {
        padding-left: 30px;
		padding-right: 30px;
		justify-content: start;
    }
}
[data-sidebar-style="modern"][data-layout="vertical"] {
    .nav-header {
        width: 9.5rem;
        .brand-title {
            display: none;
        }
        .logo-compact {
            display: none;
        }
    }
}