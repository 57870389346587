[data-theme-version="dark"] {
    .sidebar-right {
       // background-color: $d-bg;
		border-radius: 12px 0 0 12px;
			
        .nav-tabs {
            //background-color: lighten($d-bg, 3.2%);
           // border-bottom: 1px solid $d-bg;

            .nav-link {
				color:$black;
                // &.active {
                    // background-color: $d-bg;
                // }
            }
        }
		.bootstrap-select .btn{
			border-color:$border-color!important;
		}
		.filter-option-inner-inner{
			color:$black;
		}
		.dropdown-menu {
			background-color:$white;
			box-shadow:0px 0px 10px rgba(0,0,0,0.1);
			.dropdown-item{
				&.selected,&.active,&:hover{
					background-color:rgba($dark-card,0.1);
					color:rgba($black,0.8);
				}
			}
		}
		
    }
}