@import "../../abstracts/variable";

////////////////////
// Horizontal Nav
////////////////////
@media (min-width: 1023px) {
    [data-layout="horizontal"] {
        
		--nav-headbg: #{$body-bg};
		--headerbg: #{$body-bg};
		
        .nav-header {
            width: 21.563rem;
			//background: $body-bg;
            
            .nav-control {
                display: none;
            }

            .brand-logo {
				padding-left: 40px;
				padding-right: 40px;
            }
        }
		
		.search-area .form-control,
		.search-area .input-group-append .input-group-text{
			background:#fff!important;
		}

        .header {
            width: 100%;
            padding-left: 21.563rem;
			//background: $body-bg;
			box-shadow:none;
			.sub-header {
				display:none;
			}
        }

        .deznav {
            width: calc(100% - 80px);
			border-radius:$radius;
            position: relative;
            height: auto;
            padding-bottom: 0;
            top: 0;
			left:40px;
            z-index: 2;
			
			
            .slimScrollDiv {
                overflow: visible!important;

                .deznav-scroll {
                    overflow: visible!important;
                }
            }
			.deznav-scroll {
				overflow: visible!important;
			}
            .slimScrollBar {
                display: none !important;
            }

            .nav-user,
            .nav-label {
                display: none;
            }
			
            .metismenu {
                flex-direction: row;
				padding:10px 20px;
				margin-bottom: 0;
				display: inline-flex;
				flex-wrap: wrap;
					
                .collapse.in {
                    display: none;
                }
				ul{
					border-left:0;
					@at-root [data-theme-version="dark"]#{&} {
					   box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
					}
					a .badge{
						margin-right:24px;
					}
				}
                li {
                    flex-direction: column;
                    position: relative;

                    &:hover {
                        &>ul {
                            display: block;
                        }
                    }
                    
                    &>ul {
                        position: absolute;
                        height: auto !important;
                        top: 100%;
                        width: 100%;
                        min-width: 13.75rem;
                        z-index: 999;
                        left: auto;
                        right: auto;
                        padding: 0.5rem 0;
                        display: none;
                        box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
						margin:0;
						background:$white;
                        border-radius: 12px;
							
                        @at-root [data-theme-version="dark"]#{&} {
                           box-shadow:0px 0px 40px 0px rgba(82, 63, 105, 0.1);
						   background:$d-bg;
                        }

                        li {
                            padding: 0;
    
                            a {
                                transition: all .4s ease-in-out;
                                padding: 8px 20px 8px 45px;
                                margin-left: -.1rem;
								@at-root [direction="rtl"]#{&} {
									padding: 8px 45px 8px 20px;
									text-align: right;
								}
                                &:hover {
                                    border-radius: .4rem;
                                }
								&:before{
									left: 22px;
									@at-root [direction="rtl"]#{&} {
										left: auto;
										right: 21px;
									}
								}
                            }
                        }
                        
                        ul {
                            left: 100%;
                            top: 0;
							box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
							@at-root [direction="rtl"]#{&} {
								left: auto;
								right: 100%;
							}
                        }
                    }
                }

                &>li {
                    flex: 0 0 auto;
                    position: relative;
					margin: 0;
                    
					@at-root [data-theme-version="dark"]#{&} {
						border-color: rgba(255,255,255,0.07);		
						
						&.mm-active{
							border-color:transparent;
						}
						
						
					}
					&:hover,
					&.mm-active{
						padding:0;			
						& > a{
							background:transparent;
							color:var(--primary);
							//box-shadow:0 12px 15px 0 var(--rgba-primary-1);
							border-radius: 0;
							i{
								color:var(--primary);
							}
						}
					}
                    @at-root [direction="rtl"]#{&} {
                        //&:not(:first-child) {
                        //    border-right: lighten($color: $color-pallate-2, $amount: 10%);
                        //}

                        &:first-child {
                            border-right: 0;
                        }

                        

                        @at-root [data-theme-version="dark"]#{&} {
                            border-color: $d-border;
                        }
                    }
                    &>a {
                        padding: 10px 40px 10px 15px;
						font-size: 14px;
                        border-right:1px solid $border-color;
						@at-root [direction="rtl"]#{&} {
							padding: 15px 15px 15px 40px;
						}
						
                        i{
                            padding: 0 0.4375rem 0 0;

                            @at-root [direction="rtl"]#{&} {
                                padding: 0 0 0 0.4375rem;
                            }
                        }
                        .nav-badge {
                            display: none;
                        }
                        &:after {
                            right: 20px;
                            transform: rotate(-135deg) translateY(-50%);
                        }
						&:hover{
							.nav-text{
								transform: translateX(0px);
							}
						}
                    }
                    &:hover {
						border-color:transparent;
						
                        & > ul {
                            display: flex !important;
                            flex-direction: column;
                            flex-wrap: wrap;
                            height: auto !important;
							box-shadow: 5px 5px 30px 0px rgba(20, 0, 30, 0.1);
							border-radius: 12px;
							
							@at-root [data-theme-version="dark"]#{&} {
								box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.1);
								background:$d-bg;
							}
							
                        }
                    }
                    &>ul {
                        &>li {
                            &:hover {
                                ul.collapse {
                                    display: block!important;
                                    position: absolute;
                                    left: auto!important;
                                    right: -100%!important;
                                    top: 0!important;
                                }
                            }
                        }
                    }
                    &:nth-last-child(-n + 5) {
                        &>ul {
                        left: auto;
                        right: 0;
                            &>li {
                                &:hover {
                                    ul.collapse {
                                        right: auto!important;
                                        left: -100%!important;
                                    }
                                }
                            }
							&.left{
								left:0;
								&>li {
									&:hover {
										ul.collapse {
											left: 100%!important;
										}
									}
								}
								@at-root [direction="rtl"]#{&} {
									left:auto;
									right:0;
								}
							}
                        }
                    }
                    &:last-child {
                        &>ul {
                            ul {
                                left: -100%;
                            }
							&.left{
								ul{
									left: 100%;
									@at-root [direction="rtl"]#{&} {
										left:auto;
										right:100%;
									}
								}
							}
                        }
						&>a{
							border-right:0;
						}
                    }
                    @at-root [direction="rtl"]#{&} {

                        &:nth-last-child(-n + 3) {
                            &>ul {
                            left: 0;
                            right: auto;
                                &>li {
                                    &:hover {
                                        ul.collapse {
                                            right: -100% !important;
                                            left: auto !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
			.copyright,
			.main-profile{
				display:none;
			}
        }
		
        .content-body {
            margin-left: 0;
            .page-titles {
                margin-left: 0!important;
                margin-right: 0!important;
                margin-bottom: 1.875rem;
            }
        }

        .footer {
            margin-left: 0;
            margin: 0 auto;
            padding-left: 0;
        }
    }

    [data-header-position="fixed"][data-layout="horizontal"] {
        .deznav {
			top: 4.5rem;
        }
    } //ok

    [data-header-position="fixed"][data-sidebar-position="fixed"] {
        .deznav {
            position: fixed;
        }
    } //ok

    [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"] {
        .content-body {
            padding-top: 8rem;
        }
    } //ok
	[data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="modern"] {
        .content-body {
            padding-top: 13.125rem;
        }
    } //ok
	
    [data-layout="horizontal"][data-container="boxed"] {

        .footer {
            margin-left: 0;
            max-width: 1199px;
            margin: 0 auto;
        }
    }

    [data-layout="horizontal"][data-container="wide"] {
        .page-titles {
            margin-left: -30px;
            margin-right: -30px;
        }
    }

    [data-layout="horizontal"][data-sidebar-style="compact"] {
        .page-titles {
            margin-top: 0;
        }
        
        .deznav {
            .metismenu {

                &>li {
                    &>ul {
                        top: 4.4rem;
                    }
                    &>a {
                        padding: 10px 25px 8px 25px;
						border-radius:$radius;
                        &::after {
                            display: none;
                        }
						.nav-text{
							margin-top: 5px;
						}
						&>i{
							width:auto;
							height:auto;
							line-height:1;
							padding:0;
							background:transparent;
							border-radius: 0;
							margin-bottom: 0;
						}
                    }
					&.mm-active{
						&>a{
							background-color:var(--primary);
							color:#fff;
							i{
								color:#fff;
							}
						}
					}
                    li {
                        text-align: left;
                    }
                }
            }
        }
    }

    [data-sidebar-style="mini"][data-layout="horizontal"] {
        .nav-header {
            width: 7.75rem;
			padding-left: 40px;
			padding-right: 40px;
			
			    .brand-logo {
				    justify-content: start;
					padding-left: 0;
					padding-right: 0;
				}
        }
    
        .header {
            width: 100%;
            padding-left: 7.75rem;
        }

        .metismenu {
            &>li {
                &:hover {
                    a {
                        width: auto;
                        .nav-text {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] {
        .content-body {
            padding-top: 10.25rem;
        }
    }

    [data-sidebar-position="fixed"][data-layout="horizontal"] {
        .deznav.fixed {
            position: fixed;
            left: 0;
            top: 0;
        }
    }
	
	[data-sidebar-position="static"][data-layout="horizontal"] {
		.content-body {
			padding-top: 4.5rem;
		}
	}

}
