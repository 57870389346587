

//sidebar styles
[direction="rtl"] {
	text-align: right;
	
    .deznav {
        text-align: right;
        
        .metismenu {
			ul{
			
				&:after{
					left:auto;
					right:25px;
				}
				a:before{
					left:auto;
					right: 65px;
				}
				a .badge{
					float:left;
					margin-right:0;
					margin-left:12px;
				}
			}
			li{
				& > a{ 
					
					i{
					    padding: 0 0 0 1.5rem;
					}
					svg{
						margin-left:5px;
						margin-right:0;
						@at-root [data-sidebar-style="compact"]#{&} {
							left: auto;
							margin-left: auto;
							margin-right: auto;
						}
						@at-root [data-sidebar-style="icon-hover"]#{&} {
							margin-left:0;
						}
					}
				}
				ul a{
					padding-right: 6.4rem;
					padding-left: 2rem;
				}
			}
            li.active {

                &>.has-arrow {
                    &:after {
                        transform: rotate(45deg) translateY(-50%);
                    }
                }

            }

            .has-arrow {
                &:after {
                    left: 1.5625rem;
                    right: auto;
					
					@at-root [data-layout="horizontal"]#{&} {
						left: 1.125rem;
					}
					@at-root [data-sidebar-style="modern"]#{&} {
						-webkit-transform: rotate(-45deg) translateY(-50%);
						transform: rotate(-45deg) translateY(-50%);
					}
                }
            }

        }
    }

	&[data-sidebar-style="full"][data-layout="vertical"]{
		.deznav .metismenu > li .has-arrow:after {
			left: 1.5rem;
			right: auto;
		}
		.deznav {
                .metismenu {
					&>li{
						&>a{
							&:before{
								left:auto;
								right:0;
								border-top-right-radius: 0;
								border-bottom-right-radius: 0;
								border-top-left-radius: 15px;
								border-bottom-left-radius: 15px;
							}
						}
					}
				}
			}
	}
	&[data-sidebar-style="mini"]{
		.deznav .metismenu > li > a > i {
			padding: 0;
		}
		&[data-layout="vertical"]{
			.deznav .metismenu > li > ul a.has-arrow:after{
				left: 1.5625rem;
				right: auto;
			}
		}
	}
	&[data-sidebar-style="compact"] {
		.deznav{
			.metismenu li{
				& > a i {
					padding: 0;
				}
				ul a {
					padding-right: 0.625rem;
					padding-left: 0.625rem;
				}
			}
		}
	}
	&[data-sidebar-style="overlay"][data-layout="vertical"] {
		.deznav {
			.metismenu {
				ul{
					a:before{
						left:auto;
						right: 65px;
					}
				}
				li{
					ul a{
						padding-right: 4.5rem;
						padding-left: 0.625rem;
						&:before{
							left: auto;
							right: 40px;
						}
					}
				}
			}
		}
	}
	&[data-sidebar-style="icon-hover"][data-layout="vertical"] {
		.deznav {
			.metismenu {
				ul{
					a:before{
						left:auto;
						right: 65px;
					}
				}
				li{
					ul a{
						padding-right: 3.5rem;
						padding-left: 0.625rem;
						&:before{
							left: auto;
							right: 30px;
						}
					}
				}
			}
		}
	}
    &[data-sidebar-style="full"][data-layout="vertical"] {
        .menu-toggle {
            .deznav {
                .metismenu {
					
                    li {
                        &>ul {
    
                            li:hover {
                                ul {
                                    right: 11.8125rem;
                                    left: 0;
									
                                }
                            }
                        }
                    }
                }
            }
        }
    }
	&[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header .navbar{
		padding-left:0;
		padding-right:60px;
	}
	
	&[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control {
		right: auto;
		left: -50px;
	}
	&[data-layout="horizontal"]{
		.deznav{
			left:unset;
			right:40px;
			.metismenu>li{
				&:nth-child(2) >a {
					border-right:0;
				}
				&:last-child{
					//border-right:1px solid #eee;
				}
			}
		}
	}
	@media only screen and (max-width: 1023px){
		&[data-sidebar-style="overlay"] .header .navbar {
			padding-left: 40px;
			padding-right: 145px;
		}
	}
}

