
[data-theme-version="dark"]{
    .dropdown-menu {
        background-color: $dark-card;
		box-shadow: 0px 0px 0px 1px rgba(255, 255, 255,0.1);
		
		.dropdown-item {
			&.selected,
			&.selected.active,
			&.active,
			&:hover, 
			&:focus {
				background-color: $dark-card;
				color: $body-color;
			}
		}
    }
	.btn-link g [fill]{
		fill: #fff;
	}
	.btn-light{
		color:$white;
	}
	.btn-light:active, 
	.btn-light:focus, 
	.btn-light:hover{
		color:$white;
	}
    .form-control {
        background-color: transparent;
        border-color: #5b5289;
        color: $white;
    }
    .card {
        background-color: $dark-card;
		box-shadow:none;
    }
	.btn-outline-dark:hover{
		background-color: $d-bg;
		border-color: $d-bg;
	}
	.tdl-holder input[type=text],
	.tdl-holder li{
	    background-color: $dark-card;
		border-color:$d-border;
	}
	.tdl-holder input[type=text]{
		&::placeholder {
			color: $d-ctl;
		}
	}
    .tdl-holder label:hover {
		background-color: $d-border;
		color: $white;
	}
	.text-muted {
		color: $d-ctl;
	}
	.modal-content{
		background:$dark-card;
	}
	.modal-footer,
	.modal-header{
		border-color: $d-border;
	}
	.close{
	    font-weight: 400;
		color: $white;
		text-shadow: none;
		
		&:hover{
			color: $white;
		}
	}
	.header-left .dashboard_bar,
	.star-review span,
	.table strong {
		color: #fff;
	}
	.text-dark {
		color: $d-ctl;
	}
	.star-rating .product-review,
	.new-arrival-content .item{
		color: $white;
	}
	.custom-dropdown .dropdown-menu{
		border-color: $d-border;
	}
	.widget-stat .media > span{
	    background: $d-border;
	    border-color: $d-border;
		color:$white;
	}
	.plus-minus-input .custom-btn{
		background: $d-bg;
	    border-color: $d-border;
	}
	.dropdown-divider,
	.size-filter ul li{
		border-color: $d-border;
	}
	.custom-select{
	    border-color: $d-border;
		color: $d-ctl;
		background:$dark-card;
	}
	.nav-tabs{
		border-color: $d-border;
	}
	.mail-list .list-group-item.active i {
		color: $white;
	}
	hr{
		border-color: $d-border;
	}
	.grid-col{
		background:$d-bg;
	}
	
	.noUi-target{
		border-color:$d-border;
		border-radius: 8px;
		box-shadow: none;
		
		.noUi-connects{
			background:$d-border;
		}
	}
	.noUi-marker-large,
	.noUi-marker{
		background:$d-border;
	}
	.input-group-text{
		background:$d-border;
		color:$body-color;
	}
	.note-editor.note-frame{
		border-color:$d-border;
		.btn{
			color:$white;
		}
		.note-editing-area .note-editable{
			color:$white;
		}
	}
	.widget-media .timeline .timeline-panel{
		border-color:$d-border; 
	}
	.notification_dropdown .dropdown-menu-right .all-notification{
		border-color:$d-border; 
	}
	#user-activity .nav-tabs .nav-link{
		border-color:$d-border; 
        &.active{
            background:$d-bg;
            color:$white;
        }
	}
	.list-group-item-action{
		color:$body-color;
	}
	.list-group-item-action:focus, 
	.list-group-item-action:hover, 
	.list-group-item-action:focus{
		background-color:$d-bg; 
		border-color:$d-bg; 
	}
	.list-group-item.active{
		color:$white;
		border-color:var(--primary);
		&:focus, 
		&:hover, 
		&:focus{
			background-color:var(--primary); 
			border-color:var(--primary); 
			color:$white;
		}
	}
	.swal2-popup{
		background:$dark-card;
	}	
	.form-head .btn-outline-primary{
		border-color:$d-border;
	}
	.form-head .btn-outline-primary:hover{
		border-color:var(--primary);
	}
	.review-tab.nav-pills li a.nav-link{
		background:lighten($dark-card, 5);
	}
	.review-tab.nav-pills li a.nav-link{
		background:lighten($dark-card, 5);
		&.active{
			color:$white;
			background:var(--primary);
		}
	}
	.header-right .header-profile > a.nav-link .header-info span,
	.review-table .media-body p,
	.iconbox small,
	.doctor-info-details .media-body p{
		color:$white;
	}
	.iconbox p,
	.doctor-info-details .media-body span{
		color:rgba(255,255,255,0.7);
	}
	.best-doctor .timeline .timeline-panel{
		background:$dark-card;
	}
	.doctor-list .accordion__header-line{
		background:$d-border;
	}
	.review-table .disease,
	.review-table,
	.review-box{
		border-color:$d-border;
	}
	.form-head .search-area{ 
		.form-control{
			border:1px solid $d-border;
		}
		.input-group-append .input-group-text{
			background:$d-border;
		}
	}
	.patient-activity tbody tr{
	    box-shadow: 0 1px 0 0 $d-border;
	}
	.plus-box{
	    background: rgba(255,255,255,0.05);
		color: #fff;
	}
	.default-select{
		&.style-1{
			.btn{
				color:$white;
			}
		}
	}
	.text-black{
		color:$white!important;
	}
	.new-arrival-content {
		 h4 {
			a{
				color:$white;
			}
		 }
	}
	.abilities-chart .ct-chart .ct-label{
		fill:$white;
	}
	.apexcharts-legend-text{
		color:$white;
	}
	.morris_chart_height text tspan{
		fill:$white;
	}
	.toggle-switch.text-right .custom-control-label:before{
		background-color:#ffffff29;
	}
	.toggle-switch .custom-control-input:checked ~ .custom-control-label:before{
		background-color:$white;
	}
	.btn-link{
		color:$white;
	}
	.amount-bx .form-control{
		background-color:transparent;
		color:$white;
		&::placeholder{
			color:$white;
		}
	}
	.table{
		&.bg-info-hover{
			td{
				color:$white;
			}
		}
		&.bg-primary-hover{
			td{
				color:#757878;
			}
		}
		&.bg-warning-hover{
			td{
				color:$white;
			}
		}
		&.bg-secondary-hover{
			td{
				color:#757878;
			}
		}
	}
	.order-bg{
		background:#7970A3;
	}
	.deznav .metismenu > li.mm-active > a:after{
		border-color:var(--primary);
	}
	.detault-daterange .input-group-text{
		background:transparent;
		color:$white;
	}
	.form-wrapper{
		.input-group{
			background:transparent;
			border:0;
			.input-group-prepend .input-group-text{
				color:$white;
			}
		}
	}
	.search-area-2{
		.form-control{
			background-color:$dark-card;
		}
		.input-group-append{
			.input-group-text{
				background-color:$dark-card;
			}
		}
	}
	.dataTablesCard{
		background-color:$dark-card;
	}

	.compose-content .dropzone{
		background:$d-bg!important;
		.dz-message .dz-button{
			color:$white;
		}
	}
	.daterangepicker{
		background:$d-bg;
		border-color:var(--primary);
		.calendar-table{
			border-color:var(--primary);
			background:$d-bg;
			.table-condensed{
				td{
					&:hover{
						background-color:var(--primary);
						color:$white;
					}
				}
			}
		}
        &:after{
            border-bottom: 6px solid $d-bg;
        }
	}
    
    .daterangepicker select.hourselect
    , .daterangepicker select.minuteselect
    , .daterangepicker select.secondselect
    , .daterangepicker select.ampmselect{
            background: $d-bg;
            border: 1px solid $d-border;
            color:$white;
    }
    
	.daterangepicker td.off, 
	.daterangepicker td.off.in-range,
	.daterangepicker td.off.start-date, 
	.daterangepicker td.off.end-date{
		background-color:$dark-card;
		&:hover{
			background-color:var(--primary);
			color:$white;			
		}
	}
	
	.app-fullcalendar{
		.fc-button{
			background-color:$d-bg;
			border-color:$d-border;
			color:$white;
			text-shadow:none;
			&:hover,&.fc-stat-hover{
				background-color:var(--primary);
			}
		}
	}
	.swal2-popup .swal2-styled:focus {
		outline: 0;
		box-shadow: 0 0 0 2px $d-border, 0 0 0 4px var(--rgba-primary-1);
	}
	.dd-handle{
		border-color:$d-border;
	}
	.menu-toggle .deznav .metismenu li > ul{
		background:$dark-card!important;
	}
	.header-right .notification_dropdown .nav-link{
		border-color:$d-border;
	}
	.nav-tabs{ 
		.nav-link{
			color: white;
			&:hover,&.active{
			
				color:$white;
				background:transparent;
				border-color:#6418C3;
				
			}
		}	
	}
	.card-tabs{
		.nav-tabs,.nav-link,.active{

			border-bottom: 0
		}
	}
	.clockpicker-popover .popover-content{
		background-color:$dark-card;
	}
	.clockpicker-plate{
		background-color:$d-bg;
	}
	.clockpicker-popover .popover-title{
		background-color:$d-bg;
		color:$white;
	}
	.form-wizard .nav-wizard li .nav-link span{
		background-color:$d-bg;
	}
	.form-wizard .nav-wizard li .nav-link:after{
		background:$d-bg;
	}
	.form-wizard .nav-wizard li .nav-link.active:after,.form-wizard .nav-wizard li .nav-link.done:after{
		background:var(--primary);
	}
	.form-wizard .nav-wizard li .nav-link.active span
	,.form-wizard .nav-wizard li .nav-link.done span{
		background:var(--primary);
	}
    
    .amount-bx{
        border-top: 1px solid $d-border;
        border-bottom: 1px solid $d-border;
    }
    
    .bar-chart{
        line{
            stroke:$d-border;
            stroke-dasharray: 6;
        }
    }
    #lineChart{
        line{
            stroke:$d-border;
            stroke-dasharray: 6;
        }
    }
    .activity-icon{
        border-color: #f0f0f063;
    }
    .check-switch .custom-control-label:after
    , .check-switch .custom-control-label:before{
        border-color:$white;
    }
    .fc-unthemed .fc-today{
        background:$d-bg;
    }
    .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-popover .fc-header{
        background: $d-border;
    }
    .picker__box{
        background:$d-bg;
        .picker__button--clear
        , .picker__button--close
        , .picker__button--today{
            background:$dark-card;
            color:$white;
            &:hover{
                &:before{
                    color:$white;
                }
            }
        }
    }
    .picker{
        color:#999;
    }
    .dtp > .dtp-content{
        background:$d-bg;
    }
    .dtp table.dtp-picker-days tr > td > a{
       color: #68686a; 
       &.selected{
           color:$white;
       }
    }
	
	.monthly-project-chart{
		circle{
			fill:$d-bg;
		}
		.apexcharts-datalabels-group text{
			fill:$white;
		}
		.apexcharts-radialbar-track path{
			stroke:$d-bg;
		}
	}
	.timeline-chart{
		.apexcharts-series rect{
			fill:$d-bg;
		}
	}
	.kanbanPreview-bx{
		background:transparent;
		.card-body .sub-card{
			background:$dark-card;
		}
	}
	
	.message-bx .image-bx span.active
	,.contact-bx .image-bx span.active{
		border-color:$dark-card;
	}
	.users li img{
		border-color:$dark-card;
	}
	.donut-chart-sale .peity{
		path:last-child{
			fill:$d-bg;
		}
	}
	.donut-chart-sale small{
		color: white!important;
	}
	.card-tabs .nav-tabs.style-2 .nav-item .nav-link.active{
		background:transparent;
		color:$white;
	}
	.project-nav{
		border-color:$d-border;
	}
	.contact-bx{
		border-color:$dark-card;
		&:hover{
			border-color:var(--primary);
		}
	}
	.btn-light,
	.btn-light:active{
		background:$d-bg;
		border-color:$d-bg;
	}
	.users-lg li img{
		border-color:$dark-card;
	}
	.draggable.draggable-mirror{
		background:$dark-card;
		border:2px dashed $d-border;
	}
	.chat-list-area:hover, .chat-list-area.active {
		background: $d-bg;
	}
	.message-search-area .input-group-text{
		background:$d-bg;
		border-color:$d-border;
	}
	.card-tabs .nav-tabs.style-1 .nav-item .nav-link.active{
		color:$white;
	}
	.chat-box .message-received p{
		 color: #b7b7b7;
	}
	.chat-box .type-massage textarea{
		background:transparent;
	}
	.chatbox .type_msg .form-control{
		background:transparent;
		resize:none;
	}
	.message-search-area .form-control{
		border-color:$d-border;
	}
	.daterangepicker td.in-range{
		background:$dark-card;
		color: #797c87;
	}
	.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span{
		border-color:$white;
		&:hover{
			border-color:$black;
		}
	}
	.weather-btn{
		background:$dark-card;
	}
	.tbl-orders{
		svg{
			path{
				fill:$white;
			}
		}
		i{
			color:$white;
		}
		tr{
			td{
				color:$white;
			}
		}
	}
	.tbl-orders-style{
	svg{
		path{
			stroke:$white;
		}
	}
	tr{
		td{
			color:$white;
		}
	}
}	
	.bootstrap-select{
		.btn{
			background:transparent!important;
		}
	}
	.main-profile{
		i{
			color:$white;
		}
	}
	.dataTables_wrapper .dataTables_paginate{
		.paginate_button.previous,.paginate_button.next{
			background-color:$dark-card;
			&.current:hover,
			&.previous:hover,
			&.next:hover{
				color:$black;
				background:$white;
			}
			&.disabled{
				color:$white;
			}
		}
		span{
			.paginate_button{
				background-color: $dark-card;
				color: white;
				&.current{
					color: black ;
					background: $white;
					border: 0 ;
					&:hover{
						color:$black;
						background:$white;
					}
				}
				&:hover{
					color:$black;
					background:$white;
				}
			}
		}
	}
	.table.bg-primary-hover td,
	.table.bg-secondary-hover td,
	.table.bg-warning-hover td,
	.table.bg-info-hover td,
	.table.bg-success-hover td,
	.table.bg-danger-hover td{
		color:$white;
	}
	.my-profile a{
		color:#6418C3;
		background:$white;
	}
	.portofolio-social li a{
		border-color:#6418C3;
		color:$white;
	}
	.style-tabs-2{
		.nav-item{
			.nav-link{
				&:hover,&.active{
					color: $white;
					border-color: $white;
				}
			}
		}
	}
	.info-group{
		.color-style{
			color:$success;
		}
	}
	.order-tbl{
		tr{
			th{
				color:#b1adc4;
			}
		}
	}
	.dropdown-menu,.dropdown-item{
		color: #b1adc4;
	}
	.bootstrap-select,.dropdown-toggle,.filter-option-inner-inner{
		color:$white;
	}
	.main-profile{
		> p{
			color:#8f88b4;
		}
	}
	.market-line{
		line{
			stroke:#483F79;
		}
	}
	.card-tabs.style-1{
		.nav-tabs{
			.nav-link{
				&.active,&:hover{
					border-color: $white!important;
					color:$white;
				}
			}
		}
	}
	.coin-holding{
		&:after{
			background: -moz-linear-gradient(left,  rgba(30,87,153,0) 3%, rgba(42,72,130,0) 32%, rgba(59,51,99,0.49) 71%, rgba(59,51,99,0.84) 99%); 
			background: -webkit-linear-gradient(left,  rgba(30,87,153,0) 3%,rgba(42,72,130,0) 32%,rgba(59,51,99,0.49) 71%,rgba(59,51,99,0.84) 99%);
			background: linear-gradient(to right,  rgba(30,87,153,0) 3%,rgba(42,72,130,0) 32%,rgba(59,51,99,0.49) 71%,rgba(59,51,99,0.84) 99%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#d63b3363',GradientType=1 ); 
		}
	}
	.card-table{
		thead{
			background:#5B5288;
		}
	}
	.swiper-box:after{
		background: -moz-linear-gradient(top,  rgba(30,87,153,0) 0%, rgba(166,188,213,0) 33%, rgba($d-bg,0.43) 53%, rgba($d-bg,1) 79%); 
		background: -webkit-linear-gradient(top,  rgba(30,87,153,0) 0%,rgba(166,188,213,0) 33%,rgba($d-bg,0.43) 53%,rgba($d-bg,1) 79%);
		background: linear-gradient(to bottom,  rgba(30,87,153,0) 0%,rgba(166,188,213,0) 33%,rgba($d-bg,0.43) 53%,rgba($d-bg,1) 79%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#f9f9f9',GradientType=0 );
	}
	.fc-theme-standard td, .fc-theme-standard th,
	.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid, .fc-scrollgrid, table{
		border-color:$d-border;
	}
	
	.default-select.style-1 .btn:after{
		color:#fff;
	}
	
	.form-wrapper .input-group .form-control{
		border-color:$d-border;
	}
	.card-tabs.style-1 .nav-tabs {
		background-color: $d-bg;
	}
	.btn-outline-light:hover{
		color:#000;
	}
	.dropdown-menu{
		border-color:$d-border;
	}
	
	
	.chat-box-area{
		background:$d-bg;
	}
	.chat-wrapper .chat-left-sidebar .chat-fix-search .message-search-area .input-group-text,
	.chat-wrapper .chat-left-sidebar .chat-fix-search,
	.user-card .dz-media{
		border-color:$d-border;
	}
	.chat-box .message-received p{
		background:$dark-card;
		color:#fff;
	}
	.project-list-group .card:hover{
		border-color:$d-border;
	}
	
	table.dataTable.style-1 thead th{
		color: #c7c7c7;
	}
	.table{
		color: #989898;
	}
	.user-card .title a {
		color: #fff;
	}
	.card-tabs .nav-tabs.style-2 .nav-item .nav-link{
		color:rgba($white,0.5);
	}
	.market-line .apexcharts-yaxis-texts-g .apexcharts-text,
	.market-line .apexcharts-xaxis-texts-g .apexcharts-text{
		fill: $d-ctl;
	}
	.compose-content{
		.dzu-dropzone{
			background-color: #3b3363 !important;
			label{
				background-color: #3b3363 !important;
			}
		}
	} 
	.fc-theme-standard td, .fc-theme-standard th,
	.fc-theme-standard .fc-scrollgrid{
		border: unset;    
	}		
	.card-header {
		.card-title{
			color: #fff;
		}
	}
	.dd{
		.nestable-item-name{
			  border-color: #473f72;
		}
	} 
	label.mailclinet{
		background-color: #2C254A;
	}
	.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary{
		background-color: #2c2549;
	}
	.tox .tox-edit-area__iframe{
		background-color: #2c2549;
		color: $white;
	}
	.tox .tox-tbtn{
		color: $white;
		svg{
			fill: $white;
		}
	}
	.tox .tox-statusbar{
		background-color: #2c2549;
	}
	.tox .tox-statusbar__wordcount,
	.tox .tox-statusbar__path-item,
	.tox .tox-statusbar a{
		color: $white;
	}
	table.dataTable.row-border tbody th, 
	table.dataTable.row-border tbody td, 
	table.dataTable.display tbody th, 
	table.dataTable.display tbody td {
		border-color: $d-border;
		//color: $d-ctl;
	}
	.dataTables_wrapper .dataTables_info{
		color: #bfbfbf;
	}
	
	.table-primary-dark tbody tr td{
		color: $white;
	}
	.table-primary-dark table thead th{
		border-color: #e8703c;
	}
	.map-unselected{
		fill: white; 
	}
	.rsm-geography {
		stroke: #3b3363;
	}
	
	.picker-data{
		.color-time-picker{
			.react-time-picker__wrapper{
				color:#fff;
				.react-time-picker__inputGroup__input{
					color:#fff;
				}
				.react-time-picker__inputGroup__amPm{
					color:#fff;
				}
				.react-time-picker__clock-button{
					
					svg{
						stroke: #fff;
					}
				} 
			}
		}
	}
	.apex-chart{
		.apexcharts-yaxis,
		.apexcharts-xaxis{
			tspan{
				fill:$d-ctl;
			}
		}
		line{
			stroke:$d-border;
		}
		.apexcharts-legend-text{
			color:#828690 !important;
		}
	}
	.form-check-input{
		border-color:$white;
		background-color:transparent;
	}
	.list-group-item {
		color: rgba(255 ,255,255,0.6);
	}
	.swal-modal{
		background-color: $dark-card;
	}
	.swal-title{
		color: $d-ctl;
	}
	.swal-text{
		color: $d-ctl;
	}
	.swal-icon--success:after, 
	.swal-icon--success:before{
		background: $dark-card;
	}
	.swal-icon--success__hide-corners{
		background-color: $dark-card;
	}
	.accordion-item{
		background-color: transparent;
		border: 0;
	}
}
